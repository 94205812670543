import PdfLecteur from '@/components/pdfLecteur/pdfLecteur.vue'
import communLangage from '@/commun/commun.langage.js';
import fichier from '@/utils/fichier.js'

export default {
     name: 'Documents',

     components: {
          PdfLecteur
     },

     data() {
          return {
               fichierAPrevisualiser: null,
               fichiers: [],
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          },
          documentTexte(){
               return communLangage.documentTexte;
          }
     },

     methods: {
          
          recupererCV() {
               fichier.recupererfichiersCV().then((result) => {

                    this.fichiers = result.data;
                    this.fichierAPrevisualiser = this.fichiers[0]?.url;
               });
               
          }
     },

    
     created() {
          this.recupererCV();
     }
}