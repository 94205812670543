<template>
  <div class="d-flex align-items-start d-flex-tel" data-aos="zoom-in" data-aos-duration="1500" v-if="documentTexte">
    <div style="flex-basis: 30%">
      <div
        v-for="(fichier, index) in fichiers"
        :key="index"
        class="fichier m-3 p-2 d-flex justify-content-between font-weight-bold"
        :class="fichier.url == fichierAPrevisualiser ? 'bg-info' : ''"
        @click="
          () => {
            fichierAPrevisualiser = fichier.url;
          }
        "
      >
        <div>
          <span class="fa fa-file ml-1"></span>
          <span class="ml-2">{{
            documentTexte.find((x) => x.code == fichier.code).titreCV
          }}</span>
        </div>
        <div>
          <button class="btn btn-sm mr-1">
            <span class="fa fa-eye"></span>
          </button>
          <a :href="fichier.url" download="Thomas_BEAUD_CV">
            <button class="btn btn-sm" >
              <span class="fa fa-download"></span>
            </button>
          </a>
        </div>
      </div>
    </div>
    <PdfLecteur
      v-if="fichierAPrevisualiser"
      :fichierEmplacement="fichierAPrevisualiser"
      class="p-3 w-100"
      style="flex-basis: 70%"
    />
  </div>
</template>

<script src="./documents.js"></script>

<style scoped>
.fichier {
  background-color: white;
}
</style>